.ReactTable .rt-table .rt-thead.-header {
  background-color: #E6E6E6;
  font-weight: bold;
  box-shadow: none;
}

.ReactTable .rt-table .rt-tr {
  align-items: center;
}

.ReactTable .rt-table .rt-td {
  padding-top: 2px;
  padding-bottom: 2px;
}

.ReactTable .rt-table .rt-td .btn {
  padding-top: 0px;
  padding-bottom: 0px;
}

.ReactTable .rt-table .-pagination {
  box-shadow: none;
}

.ReactTable .rt-table .background-color-highlight, .ReactTable .rt-table .background-color-highlight:hover {
  background-color: #e0e0e0 !important;
}

.ReactTable .rt-table .rt-td.sm-margin .rt-expander {
  margin-left: 5px;
  margin-right: 0px;
}

.ReactTable,
.ReactTable .rt-table .rt-tr .rt-td.overflow-visible,
.ReactTable .rt-table .rt-tbody,
.ReactTable .rt-table {
  overflow: visible;
}

.ReactTable .rt-tr.pad-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.dda-list .ReactTable .rt-noData {
  background: transparent;
}

.ReactTable .rt-td .checkbox {
  margin-top: 2px;
  margin-bottom: -3px;
}
