.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.5rem;
  text-align: center;
  font-weight: bold;
  background-color: rgb(120, 120, 120);
}

.footer-logo-signin {
  height: 50px;
}

.footer-logo {
  height: 70px;
}

.embossed-heavy {
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 
    inset 0 2px 3px rgba(255,255,255,0.3),
    inset 0 -2px 3px rgba(0,0,0,0.3),
    0 1px 1px rgba(255,255,255,0.9);
}