.panel.welcome {
  margin: 0px auto;
  max-width: 260px;
  font-size: x-large;
  border-width: 0px;
  box-shadow: none;
  color: #777;
  padding-top: 10px;
}

.center {
  margin-right: auto;
  margin-left: auto;
}

.alignCenter {
  display: flex;
  justify-content: center;
}

.panel.notice {
  margin: 20px auto;
  max-width: 900px;
  font-size: x-large;
  border-width: 0px;
  box-shadow: none;
  color: #777;
  padding-top: 10px;
}

.panel.shopTableTitle {
  margin: 20px auto 0px auto;
  text-align: center;
  font-size: large;
  border-width: 0px;
  box-shadow: none;
  color: #777;
  padding-top: 10px;
}

.panel.shopTable {
  margin: 10px auto;
  width: 40%;
  font-size: x-large;
  border-width: 0px;
  box-shadow: none;
  color: #777;
  padding-top: 10px;
}

.panel.registrationInfo {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: large;
  border-width: 0px;
  box-shadow: none;
  color: #777;
  padding-top: 10px;
}

.registrationInfo li {
  margin-top: 10px;
}

.paymentTitle {
  margin-bottom: 0px;
}

.orange-text {
  color: orange;
}

@media (max-width: 400px) {

  .panel.shopTable {
    width: 100%;
  }
} 