
.header-logo {
  background-color: white;
  padding-top: 0px;
  padding-bottom: 10px;
}

.header-logo .logo {
  max-height: 90px;
  max-width: 300px;
  top: 50%;
  /* -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
  margin-top: 20px;
  z-index: 270;
}

.header-logo .white-background {
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 250;
}

.isslogo-header {
  padding: 10px 10px 10px 10px;
  width: 150px;
  max-width: 150px;
  max-height: 70px;
  margin: auto;
  top: 0; bottom: 0; left: 0; right: 0
}

.overlay-container {
  padding-top: 60px;
  padding-bottom: 60px;
}

.header-row {
  /* padding: 15px; */
  /* margin-bottom: 20px; */
  /* background-image: url('/img/iss_header_cars.png') !important;
  background-size: auto 100%;
  background-position: right;
  background-color: #000000 !important;
  background-repeat: no-repeat; */
  z-index: 100;
}

.header-info {  
  padding-right: 20%;
  padding-top: 20px;
  z-index: 101;
}

.header-row, .header-logo, .header-info {
  top: 0px;
  left: 50px;
  width: 100%;
}

.header-padding {
  padding: 60px;
  margin-bottom: 0px !important;
}

.header-row .header-right {
  padding: 0px 15px 0 0;
}

.userName {
  margin-top: 10px;
}

.userName.oneline {
  display: none;
}
.twolines {
  display: block;
}

@media (max-width: 1500px) {

}

@media (max-width: 1000px) {
  .header-info {
    padding-right: 20%;
    padding-top: 10px;  
  }  
  .overlay-container {
    padding-top: 40px;
    padding-bottom: 40px;  
  } 
  /*.header-logo {*/
    /*padding-top: 40px;*/
    /*padding-bottom: 40px;    */
  /*}*/
  /*.header-logo .logo {*/
    /*max-height: 60px;*/
    /*max-width: 190px;*/
    /*left: 35px;*/
  /*}*/
  .header-padding {
    padding-top: 40px;
    padding-bottom: 40px;    
  }
  .userName {
    margin-top: 5px;
  }
  .userName.oneline {
    display: block;
    text-shadow: 2px 2px #333333;
  }
  .twolines {
    display: none;
  }
  .nav-links {
    right: 200px;
    top: 7px;
  }
}

@media(max-width: 800px) {
  /*.header-logo .logo {*/
    /*max-height: 40px;*/
    /*max-width: 150px;*/
    /*left: 35px;*/
  /*}*/
}

@media (max-width: 500px) {

}
