@media all and (min-width: 480px) {
  .Signup {
    padding: 20px 0;
  }

  .Signup form {
    margin: 0 auto;
    padding: 25px 50px 25px;
    max-width: 500px;
  }
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
}

.left-padding {
  padding-left: 10px;
}
