.LoaderButton .spinning.glyphicon, .loading-panel .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

.loading-panel {
  margin-top: 40px;
  text-align: center;
}

.loading-panel .glyphicon {
  font-size: 2.0em;
}