html {
  height: 100%;
  box-sizing: border-box;
  padding: 0px;
}

body {
  position: relative;
  margin: 0;
  min-height: 100%;
  padding-bottom: 10rem !important;
  color: #777 !important;
}

.App.introPage {
  background-image: url("/img/autobg2-70.png");
  background-size: cover;
  background-position: center;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}

@media(max-width: 480px) {
  .App.introPage {
    background-size: cover;
  }
}

.App .navbar-brand {
  font-weight: bold;
}

.drop-shadow-dark, .drop-shadow {
    -webkit-box-shadow: 0 8px 6px -6px #4a4a4a !important;
       -moz-box-shadow: 0 8px 6px -6px #4a4a4a !important;
            box-shadow: 0 8px 6px -6px #4a4a4a !important;
}

.white-container {
  background-color: transparent;
  border-radius: 6px;
}
 
.Login {
  margin-top: 30px;
}

.Login form {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.whiteText {
  color: white !important;
}

.redText {
  color: red !important;
}

.alertText {
  color: #f5810c !important;
  line-height: 1.5em;
}

.header-logo {
  margin-bottom: 20px;
}

.header-text, .navbar-nav .header-text a, .navbar-nav .header-text a:hover {
  color: #BD0B29;
  cursor: default;
}

.navbar-nav > li.header-text > a:hover, .navbar-nav > li.header-text:hover {
  background-color: transparent !important;
}

.navbar-default .navbar-nav .header-text a {
  font-size: 18px;
  font-weight: bold;
  color: #BD0B29 !important;
}

.navbar-default .navbar-nav .header-help a {
  color: #424344 !important;
  padding-left: 5px;
  padding-right: 5px;
}

.navbar-default .navbar-nav .header-help.selected a:hover {
  color: #424344 !important;
}

.navbar-default .navbar-nav .header-help.selected a {
  color: rgb(230, 230, 230) !important;
}

.navbar-default .navbar-nav .header-help.selected .dropdown-menu a {
  margin-right: 0px;
  color: #424344 !important;
}

.login-btn-container {
  margin: 30px 50px 0px 50px;
  box-shadow: none;
  display: flex;
  flex-direction: row-reverse;
}

.login-btn-container div {
  display: flex;
}

.mobile-login-btn-container div {
  display: none;
}

.btn-container {
  margin-right: auto;
  margin-left: auto;
  width: 50%;
  max-width: 200px;
}

.btn-container-wide {
  margin-right: auto;
  margin-left: auto;
  width: 50%;
  max-width: 300px;
}

.captcha-container {
  margin: 10px auto;
  width: 304px;
}

.dropzone-container {
  margin-right: auto;
  margin-left: auto;
  width: 30%;
  max-width: 200px;
  margin-top: 30px;
}

.dropzone-container Icon {
  width: 100%;
  height: 100%;
}

.dropzone-container i {
  margin-left: -5px;
}

.dropzone {
  width: 100px;
  height: 100px;
  margin-right: auto;
  margin-left: auto;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
}

.panel.tag-line-container {
  margin: 10px auto;
  padding: 30px 0;
  max-width: 60%;
  box-shadow: none;
  text-align: center;
  background-color: transparent;
}

.panel.tag-line-container .tag-line {
  width: 97%;
  margin-right: auto;
  margin-left: auto;
  /*color: #6C6D70; */
  color: #505153;
  font-size: 32px;
  font-weight: bold;
  font-style: italic;
  font-family: Arial;
  line-height: normal;
}

.landing-logo {
  background-color: transparent;
  padding-top: 20px;
  padding-bottom: 10px;
}

.landing-logo .logo {
  max-height: 120px;
  max-width: 600px;
  margin-top: 50px;
  margin-bottom: 20px; 
  top: 50%;
  /* -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
  left: 50px;
  z-index: 270;
}

.red-button.sm-btn {
  font-size: 15px;
  padding: 5px 15px;
  border-width: 2px;
}

.red-button.lg-btn {
  font-size: 28px;
  padding: 10px;
  border-width: 3px;
}

.red-button {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  font-weight: bold;
  color: white;
  background-color: #6C6D70;
  border-color: #BD0B29;
  border-radius: 5px;
  border-width: 2px;
}

.btn-default.red-button[disabled], .btn-default.red-button[disabled]:hover {
  color: rgb(180, 180, 180);
  background-color: #6C6D70;
  border-color: #BD0B29;
  text-decoration: none;
}

.btn-default.red-button:hover {
  color: white;
  background-color: #6C6D70;
  border-color: #BD0B29;
  text-decoration: underline;
}

.red-button a {
  color: white;
}

.error {
  border-color: red !important;
}

span.error {
  color: red;
}

.panel.button-panel {
  margin-right: auto;
  margin-left: auto;
  max-width: 20%;
  box-shadow: none;
}

.landing-logo .white-background {
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 250;
}

.flex-container {
  display: flex;
  flex-direction: row;
}

.flex-inline-item {
  display: flex;
}

.panel-frame {
  margin: 40px auto;
  width: 90%;
}

.panel-20-percent {
  width: 20%;
}

.panel-35-percent {
  width: 35%;
}

.panel-80-percent {
  width: 80%;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}

.text-center {
  text-align: center;
}

.table>tbody>tr>td.padding-left-20 {
  padding-left: 20px;
}

.valign-center {
  margin-top: 10px;
  margin-bottom: 10px;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-negative-10 {
  margin-top: -10px;
}

.select-container {
  padding-bottom: 10px;
}

.select-container-pad-20 {
  padding-bottom: 20px;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border-width: 2px;
  color: #666;
}

.nav-tabs > li > a {
  font-size: 18px;
  color: #999;
}

.nav-tabs > li {
  margin-bottom: -2px;
}

.nav-tabs {
  border-bottom: 2px solid #ddd;
}

.navbar-default .navbar-nav>.open>a {
  background-color: #424344;
}

.auto-auth-table, .auto-auth-table .ag-header {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

.tool-sn-font {
  font-family: 'Roboto Mono', monospace;
}

.ag-theme-balham .ag-row {
  color: #666;
}

.ag-theme-balham .ag-root {
  border-radius: 4px;
}

.ag-theme-balham.auto-auth-table {
  margin-bottom: 20px;
}

.responsive {
  width: 100%;
  height: auto;
}

.btn.btn-link {
  padding-right: 2px;
  padding-left: 2px;
}

.no-left-padding {
  padding-left: 0px;
}

.ag-theme-balham .width100 .ag-header-cell-label {
  width: 100%;
}

i.icon.no-margin-right {
  margin-right: 0px;
}

.spacer10 {
  margin-right: 10px;
  margin-left: 10px;
}

.spacer15 {
  margin-left: 15px;
}

.spacer50 {
  margin-left: 50px;
}


.infoLinks {
  font-size: large;
  color: #8c081e;
  font-weight: 600;
}

.infoLinks a:visited {
  color: #8c081e;
}

/* Input Field Affects */
.form-group {
  position: relative;
}

label.float-label {
  display: inline-block;
  position: absolute;
  left: 15px;
  top: 12px;
  transition: all 150ms ease-in;
  color: rgb(154, 154, 154);
  font-weight: normal;
}

/* when the label is activated, it jumps up (using translateY) */
        /* you could add animation here as well */

label.float-label.field-active {
  transform: translateY(-23px);
  font-size: .9em;
  color: #6C6D70;
  text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff;
 }
 
.floating-label {
  -webkit-appearance: none !important;
}
 
.ui.breadcrumb {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

label.float-label {
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 12px;
  transition: all 150ms ease-in;
  color: rgb(154, 154, 154);
  font-weight: normal;
}

/* when the label is activated, it jumps up (using translateY) */
        /* you could add animation here as well */

label.float-label.field-active {
  transform: translateY(-23px);
  font-size: .9em;
  color: #6C6D70;
  text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff;
 }
 
.floating-label {
  -webkit-appearance: none !important;
 }


@media (max-width: 1300px) {
  .panel.button-panel {
    max-width: 30%;
  }

}

@media (max-width: 900px) {
  .panel.button-panel {
    max-width: 40%;
  }

}

@media (max-width: 700px) {
  .panel.button-panel {
    max-width: 60%;
  }
  .landing-logo {
    padding-top: 20px;
  }
  .landing-logo .logo {
    max-width: 380px;
  }
  .panel.tag-line-container {
    max-width: 70%;
    padding: 35px 0;
    background: transparent;
  }
  .panel.tag-line-container .tag-line {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .login-btn-container {
    display: none;
  }
  .mobile-login-btn-container div {
    display: block;
  }
  .header-logo {
    display: none;
  }
  .hide-first {
    display: none !important;
    width: 0px !important;
  }
}

@media (max-width: 480px) {
  .white-container {
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media (max-width: 768px) {
  .navbar-default .navbar-nav .header-help a {
    color: rgb(230, 230, 230) !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  .navbar-default .navbar-nav .header-help.selected a:hover {
    color: rgb(230, 230, 230) !important;
  }
  
  .navbar-default .navbar-nav .header-help.selected a {
    color: rgb(230, 230, 230) !important;
  }
  
  .navbar-default .navbar-nav .header-help.selected .dropdown-menu a {
    margin-right: 0px;
    color: rgb(230, 230, 230) !important;
  }
}

@media (max-width: 400px) {
  .red-button.lg-btn {
    font-size: 20px;
  }
  .landing-logo .logo {
    max-width: 300px;
  }
  .panel.tag-line-container {
    max-width: 80%;
    padding: 15px 0;
  }
  .panel.tag-line-container .tag-line {
    font-size: 20px;
  }
  .Login {
    margin-top: 15px;
  }
  .hide-second {
    display: none !important;
    width: 0px !important;
  }
  .auto-auth-table {
    width: 250px;
  }
}

@media print {
  p.spacer15 {
    padding-left: 15px;
  }
}
