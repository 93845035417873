.navbar-default .navbar-collapse, .navbar-header {
  border-radius: 0px;
  border-top-width: 2px;
  border-top: 2px solid #BD0B29;
  border-bottom: 2px solid #BD0B29;
  background: rgb(160, 160, 160);
}

.navbar-collapse {
  min-height: 30px;
  margin-top: 0px;
}

.navbar-brand {
  height: 30px;
}

.navbar {
  border: 0px;
}
.navbar > .container {
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: white;
}

.navbar-nav > li > a, .navbar-brand {
  padding-top: 10px;
  padding-bottom: 10px;
}

.navbar-nav > li > a > a, .navbar-nav > li > a {
  font-size: 20px;
  color: rgb(230, 230, 230) !important;
}

.navbar-nav > li > a > a:hover, .navbar-nav > li > a:hover {
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
}

.navbar-nav > li:hover, .navbar-nav > li > a:hover {
  background-color: rgb(180, 180, 180) !important;
}

.navbar-nav > li.selected > a, .navbar-nav > li.selected > a > a, .navbar-nav > li.selected > a, .navbar-nav > li.selected > a:hover, .navbar-nav > li > a.selected:hover {
  color: rgb(230, 230, 230) !important;
  cursor: default;
}

.navbar-nav > li.selected:hover, .navbar-nav > li > a.selected:hover {
  background-color: #424344 !important;
  color: rgb(230, 230, 230) !important;
}

.navbar-collapse li.navitem {
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  z-index: 1000;
}

.navbar-collapse li.navitem a {
  padding-left: 15px;
  padding-right: 15px;
}

.navbar-collapse.logged-in > .container {
  margin-left: 40px;
  width: 95%;
}

.nav-item.selected {
  background-color: #424344;
  /*color: rgb(180, 180, 180) !important;*/
}

.nav-item.disabled a {
  color: #666666;
}

li.navbar-spacer a {
  padding-right: 5px;
  padding-left: 5px;
}

.navbar-default .navbar-toggle {
  background-color: #6C6D70 !important;
  border-color: #BD0B29;
  border-width: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 25px;
}

.navbar-default .navbar-toggle > .icon-bar {
  background-color: white;
}

.navbar-brand {
  display: none;
  width: 40%;
  height: auto;
  margin-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.nav-item.login {
  display: none;
}

.navbar-brand .brand-image {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .nav-item.login {
    display: block;
  }
  .navbar-default .navbar-header {
    margin-top: 1px;
  }
  .navbar-nav > li.header-text {
    display: none;
  }
  .navbar-nav > li {
    margin-left: 5px;
    margin-right: -5px;
  }
  .navbar-brand {
    display: block;
  }
  .header-logo {
    display: none;
  }
  .navbar-default .navbar-collapse {
    background-color: rgb(180, 180, 180);
  }
  .navbar-nav > li > a.selected {
    margin-right: -15px;
  }
}

@media (max-width: 500px) {
  .navbar-brand {
    width: 55%;
  }
}

